
var tenSeries = ['Ten ', 'Eleven ', 'Twelve ', 'Thirteen ', 'Fourteen ',
    'Fifteen ', 'Sixteen ', 'Seventeen ', 'Eighteen ', 'Nineteen '];
var units = ['', 'One ', 'Two ', 'Three ', 'Four ', 'Five ', 'Six ', 'Seven ', 'Eight ', 'Nine '];
var tens = ['', '', 'Twenty ', 'Thirty ', 'Forty ', 'Fifty ', 'Sixty ',
    'Seventy ', 'Eighty ', 'Ninety '];
var weights = ['Crore ', '', 'Hundred ', 'Thousand ', '', 'Lakh ', '',];
var paise = 'cent ';
var zero = 'Zero ';
var rs = 'Rupees ';
//START  : SD : 08 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area
var dollar = 'USD ';//'Dollar ';
var euro = 'Euro ';
var gbp = 'GBP ';
var jpeeyn = 'JPEEYN ';
//END  : SD : 08 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area
var and = 'And ';
/*****************************************************************************
*   Function showWords                                                       *
*   Parameters : Field with Given Values in Number in it,                    *
*                Field with Required Values in Words                         *
*	                                                                         *
*   Calculates Numbers with any number of digits as supported by JavaScript  *
*                                                                            *
*   Limit on Number (for IE 6.0.2800.1106) is : 14 Digit Number              *
*****************************************************************************/

//START  : SD : 08 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area
/*
function showcurrDtls(c)
{
//alert(c);
var code;
code=c.value;
if(code=='101'){
//alert ("euro");
return euro;
}else if(code=='102')
{
//alert ("gbp");
return gbp;
}else if(code=='103')
{
//	alert ("jpeeyn");
return jpeeyn;
}else if(code=='104')
{
//	alert ("rs");
return rs;
}else{
//	alert ("doller");
    return doller;
}

}

*/


//START  : SD : 28 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area
function showWords(n, c)	//number field , word field , currency field
{
    var numFloat = 0.0, numInt = 0, result, wt = 0, i = 0, j = 0, chk, neg = false, validFields = true, val;
    val = c;
    var w = '';
    if (n == null) {
        numFloat == 0.0;
        // alert('Source field does not exist');
        // validFields = false;
    }
    else {
        chk = n;

        /************************************************************
        Addded by Guru:
        Description: To check if the value in null or blank;
        ************************************************************/
        if (chk.length == 0) {
            w = '';
            return w;
        }

        if (isNaN(chk)) {
            w = '';
            return w;
        }

        if ((isNaN(chk)) || (chk == 0))
            numFloat == 0.0;
        else {
            if (chk < 0) {
                chk = -chk;
                neg = true;
            }
            numFloat = parseFloat(chk);
        }
    }


    numInt = Math.round((numFloat - parseInt(numFloat)) * 100);
    result = "";
    if ((numInt > 9) && (numInt < 20)) {
        numInt -= 10;
        result = tenSeries[numInt] + result;
        result = and + result + paise;
    }
    else if (numInt == 0)
        result = '';
    else {
        result = units[(numInt % 10)] + result;
        result = tens[parseInt(numInt / 10)] + result;
        result = and + result + paise;
    }
    // PAISE CALCULATED
    numInt = parseInt(numFloat);
    if (numInt != 0) {
        //alert("Hi c2n Team"+numInt);	
        while (numInt != 0) {
            if ((wt % 7) != 2) {
                i = numInt % 10;		//units digit
                j = parseInt((numInt % 100 - i) / 10);		//tens digit
                if (j == 1)
                    result = tenSeries[i] + (wt == 0 ? '' : weights[(wt % 7)]) + result;
                else {
                    if ((j != 0) || (i != 0))
                        result = tens[j] + units[i] + (wt == 0 ? '' : weights[(wt % 7)]) + result;
                    else
                        if ((wt != 0) && ((wt % 7) == 0))
                            result = weights[0] + result;			// Print crores even if digits are 0
                }

                wt += 2;
                numInt = parseInt(numInt / 100);
            }
            else					//For hundreds, there is only 1-9
            {
                i = numInt % 10;		//units digit
                if (i != 0)
                    result = units[i] + weights[(wt % 7)] + result;
                numInt = parseInt(numInt / 10);
                ++wt;
            }
        }
        //alert(result);
    }
    else {
        result = zero + result;
    }

    //START  : SD : 28 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area

    w = result;

    if (val == 101) {

        result = (neg ? "(Minus) " : "") + euro + result + "Only";

    } else if (val == 102) {

        result = (neg ? "(Minus) " : "") + gbp + result + "Only";

    } else if (val == 103) {

        result = (neg ? "(Minus) " : "") + jpeeyn + result + "Only";

    } else if (val == 104) {

        result = (neg ? "(Minus) " : "") + rs + result + "Only";

    } else if (val == 100 || val == 1) {

        result = (neg ? "(Minus) " : "") + dollar + result + "Only";

    }

    w = result;

    //alert(result);
    return w;
}



//START  : SD : 08 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area
/*
function showcurrDtls(c)
{
alert(c);
var dtls;
dtls=c.value;
alert(val);
}
*/


function showAmtWords(n)	//number field , word field
{


    //START  : SD : 08 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area
    //var paise='Paise ';


    var rs = 'Rupees ';
    var doller = "USD "; //'Doller';
    var euro = 'Euro ';
    var gbp = 'GBP ';
    var jpeeyn = 'JPEEYN';

    var numFloat = 0.0, numInt = 0, result, wt = 0, i = 0, j = 0, chk, neg = false, validFields = true;

    if (n == null) {
        numFloat == 0.0;
        // alert('Source field does not exist');
        validFields = false;
    }
    else {
        chk = n;

        /************************************************************
        Addded by Guru:
        Description: To check if the value in null or blank;
        ************************************************************/


        if ((isNaN(chk)) || (chk == 0))
            numFloat == 0.0;
        else {
            if (chk < 0) {
                chk = -chk;
                neg = true;
            }
            numFloat = parseFloat(chk);
        }
    }


    if (validFields) {

        numInt = Math.round((numFloat - parseInt(numFloat)) * 100);

        result = "";
        if ((numInt > 9) && (numInt < 20)) {
            numInt -= 10;
            result = tenSeries[numInt] + result;
            result = and + result + paise;
        }
        else if (numInt == 0)
            result = '';
        else {
            result = units[(numInt % 10)] + result;
            result = tens[parseInt(numInt / 10)] + result;
            result = and + result + paise;
        }
        // PAISE CALCULATED
        numInt = parseInt(numFloat);
        if (numInt != 0) {

            while (numInt != 0) {
                if ((wt % 7) != 2) {
                    i = numInt % 10;		//units digit
                    j = parseInt((numInt % 100 - i) / 10);		//tens digit
                    if (j == 1)
                        result = tenSeries[i] + (wt == 0 ? '' : weights[(wt % 7)]) + result;
                    else {
                        if ((j != 0) || (i != 0))
                            result = tens[j] + units[i] + (wt == 0 ? '' : weights[(wt % 7)]) + result;
                        else
                            if ((wt != 0) && ((wt % 7) == 0))
                                result = weights[0] + result;			// Print crores even if digits are 0
                    }

                    wt += 2;
                    numInt = parseInt(numInt / 100);
                }
                else					//For hundreds, there is only 1-9
                {
                    i = numInt % 10;		//units digit
                    if (i != 0)
                        result = units[i] + weights[(wt % 7)] + result;
                    numInt = parseInt(numInt / 10);
                    ++wt;
                }
            }
            //alert(result);
        }
        else {
            result = zero + result;
        }
        //START  : SD : 08 NOV'2016 [artf565946] - CIM-GIFT Changes - show currency on text area
        result = (neg ? "(Minus) " : "") + rs + result + "Only";

        return result;
    }
}

function reduceDecimal(amount1) {
    if (!isNaN(amount1.value)) {
        var aValue;
        aValue = amount1.value;
        if (aValue.indexOf(".") > -1)
            amount1.value = aValue.substring(0, aValue.indexOf(".") + 3);
        else
            amount1.value = amount1.value;
    }
}
