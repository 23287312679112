function _elk_DS(){
    
    function a(){for(;_elk_send_queue.length>0&&0==_elk_desksignObj._elk_socket.bufferedAmount;){var b=_elk_send_queue[0];_elk_send_queue.shift(),_elk_desksignObj._elk_socket.send(b)}_elk_send_queue.length>0&&_elk_desksignObj._elk_socket.bufferedAmount>0?window.setTimeout(a,5):_elk_enablePage()}if(_elk_DSObjCount>0)return void alert("Cannot create object of type _elk_DS more than once");_elk_DSObjCount++,this.bActiveX=!1,this.activeXObj=null,this._elk_socket=null,this._elk_connectionOpen=!1,this._elk_userCallback,

this._elk_setCallback=function(a){if(void 0!==this._elk_socket&&null!=this._elk_socket)try{this._elk_socket.onmessage=function(c){var d=JSON.parse(c.data);a(d.resp,d.status,d.params)}}catch(a){alert("Error:"+a)}},

this._elk_open=function(){var a;if(void 0===this._elk_socket||null==this._elk_socket){try{var b;b=_elk_new_proto?"E-Lock-protocol2":"E-Lock-protocol",a=(document.URL.substring(0,5),"wss://127.0.0.1:7681/xxx"),"undefined"!=typeof MozWebSocket?this._elk_socket=new MozWebSocket(a,b):this._elk_socket=new WebSocket(a,b),this._elk_socket.onopen=_elk_onopenfn,this._elk_socket.onclose=_elk_onclosefn,this._elk_socket.onerror=_elk_onerrorfn}catch(a){_elk_enablePage(),this._elk_socket=null,_elk_switchToActiveX()}void 0!==this._elk_socket&&null!=this._elk_socket&&1==this._elk_socket.readyState?(_elk_enablePage(),this._elk_connectionOpen=!0):this._elk_connectionOpen=!1}},

this._elk_close=function(){void 0!==this._elk_socket&&null!=this._elk_socket&&this._elk_socket.close(),this._elk_socket=null,this._elk_connectionOpen=!1},

this._elk_start_app=function(){window.location="elk://"},

this._elk_end_app=function(){try{void 0!==this._elk_socket&&null!=this._elk_socket&&this._elk_close()}catch(a){}},

this._elk_initialize=function(a){_elk_detectBrowser(),_elk_createDiv(),_elk_disablePage(),this._elk_userCallback=a,_elk_desksignObj._elk_open(),_elk_desksignObj._elk_setCallback(_elk_desksignObj._elk_userCallback)},

this._elk_uninitialize=function(){this._elk_end_app()},

this._elk_check_connection=function(){if(void 0===this._elk_socket||null==this._elk_socket||!this._elk_connectionOpen){if(void 0!==this._elk_socket&&null!=this._elk_socket&&1==this._elk_socket.readyState)return _elk_enablePage(),this._elk_connectionOpen=!0,!0;if(void 0===this._elk_socket||null==this._elk_socket||!this._elk_connectionOpen)return alert("Could not connect to E-Lock SignApp.\n\n"+_elk_lastmsg),!1}return!0},

this._elk_send_request=function(a){if(this._elk_check_connection())try{if(_elk_new_proto){var c,b=_elk_byteLength(a);c=bEdge||bIE?5e4:1e3;var d=0;for(a=_elk_round8(b)+a,b+=8;b>0;){if(!(b>c)){this._elk_buffered_send(a.substr(d));break}this._elk_buffered_send(a.substr(d,c)),d+=c,b-=c}}else this._elk_buffered_send(a)}catch(a){alert("Exception: "+a)}},

this._elk_buffered_send=function(b){0==_elk_send_queue.length&&0==this._elk_socket.bufferedAmount?this._elk_socket.send(b):(_elk_send_queue.push(b),1==_elk_send_queue.length&&(_elk_disablePage(),window.setTimeout(a,5)))},

this._elk_activeX_returnResult=function(a,b){var d,c={};if(this.bActiveX){var e;"SignFile"!=a&&"SignPDFInMemory"!=a?(e=this.activeXObj.GetOperationStatusString(),d=this.activeXObj.GetOperationStatusCode()):(e=a+" functionality is not supported in this mode of signing",d=-1),void 0!==b&&null!=b&&""!=b&&("GetSubjectDN"==a||"GetIssuerDN"==a?c.dn=b:"SignDataDetached"!=a&&"SignDataInMemoryEx"!=a&&"SignEncodedDataInBatch"!=a||(c.sig=b)),c.statusCode="0x"+_elk_round8(d),this._elk_userCallback(a,e,c)}},

this.SetTSParameters=function(a,b,c,d,e){if(this.bActiveX)this.activeXObj.SetTSParameters(a,b,c,d,e),this._elk_activeX_returnResult("SetTSParameters","");else{var f='{"server-host-name":"'+a+'","server-port":'+b+',"server-url":"'+c+'","ts-before":'+d+',"policy-id":"'+e+'"}';this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SetTSParameters","params":'+f+" }")}},

this.SignPDFData=function(a,b,c,d,e,f,g,h,i,j,k,l,m,n){if(this.bActiveX)this.activeXObj.SignPDFData(a,b,c,d,e,f,g,h,i,j,k,l,m,n),this._elk_activeX_returnResult("SignPDFData","");else{a=a.replace(/\\/g,"\\\\"),b=b.replace(/\\/g,"\\\\"),g=g.replace(/\\/g,"\\\\");var o='{"in-file":"'+a+'","out-file":"'+b+'","signing-time":'+c+',"store-type":'+d+',"issued-to":"'+e+'","issued-by":"'+f+'","pfx-file":"'+g+'","pwd":"'+h+'","silent-mode":'+i+',"reseller-code":'+k+',"signature-format":'+m+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SignPDFData","params":'+o+" }")}},

this.SignDataInMemoryEx=function(a,b,c,d,e,f,g,h,i,j,k,l){if(this.bActiveX)b=this.activeXObj.SignDataInMemoryEx(a,b,c,d,e,f,g,h,i,j,k,l),this._elk_activeX_returnResult("SignDataInMemoryEx",b);else{h=h.replace(/\\/g,"\\\\");var m='{"data":"'+a+'","sig":"'+b+'","detached":'+c+',"signing-time":'+d+',"store-type":'+e+',"issued-to":"'+f+'","issued-by":"'+g+'","pfx-file":"'+h+'","pwd":"'+i+'","silent-mode":'+j+',"reseller-code":'+l+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SignDataInMemoryEx","params":'+m+" }")}},

this.SignEncodedDataInBatch=function(a,b,c,d,e,f,g,h,i,j,k){if(this.bActiveX)sig=this.activeXObj.SignEncodedDataInBatch(a,b,c,d,e,f,g,h,i,j,k),this._elk_activeX_returnResult("SignEncodedDataInBatch",sig);else{g=g.replace(/\\/g,"\\\\");var l='{"data":"'+a+'","detached":'+b+',"signing-time":'+c+',"store-type":'+d+',"issued-to":"'+e+'","issued-by":"'+f+'","pfx-file":"'+g+'","pwd":"'+h+'","silent-mode":'+i+',"reseller-code":'+k+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SignEncodedDataInBatch","params":'+l+" }")}},

this.SignDataAttached=function(a,b,c,d,e,f,g,h,i,j,k){if(this.bActiveX)this.activeXObj.SignDataAttached(a,b,c,d,e,f,g,h,i,j,k),this._elk_activeX_returnResult("SignDataAttached","");else{a=a.replace(/\\/g,"\\\\"),k=k.replace(/\\/g,"\\\\"),f=f.replace(/\\/g,"\\\\");var l='{"in-file":"'+a+'","out-file":"'+k+'","signing-time":'+b+',"store-type":'+c+',"issued-to":"'+d+'","issued-by":"'+e+'","pfx-file":"'+f+'","pwd":"'+g+'","silent-mode":'+h+',"reseller-code":'+j+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SignDataAttached","params":'+l+" }")}},

this.SignDataDetached=function(a,b,c,d,e,f,g,h,i,j,k,l,m){var n="";if(this.bActiveX)n=this.activeXObj.SignDataDetached(a,b,c,d,e,f,g,h,i,j,k,l,m),this._elk_activeX_returnResult("SignDataDetached",n);else{a=a.replace(/\\/g,"\\\\"),i=i.replace(/\\/g,"\\\\");var o='{"in-file":"'+a+'","in-signature":"'+b+'","is-encoded":'+c+',"is-hash":'+d+',"signing-time":'+e+',"store-type":'+f+',"issued-to":"'+g+'","issued-by":"'+h+'","pfx-file":"'+i+'","pwd":"'+j+'","silent-mode":'+k+',"reseller-code":'+m+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SignDataDetached","params":'+o+" }")}},

this.ConfigSigBlock=function(a,b,c,d,e,f,g,h){if(this.bActiveX)this.activeXObj.ConfigSigBlock(a,b,c,d,e,f,g,h),this._elk_activeX_returnResult("ConfigSigBlock","");else{var i='{"elements":'+a+',"width":'+b+',"height":'+c+',"image-position":'+d+',"orientation":'+e+',"sig-text":"'+f+'","x-margin":'+g+',"y-margin":'+h+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"ConfigSigBlock","params":'+i+" }")}},

this.EnablePDFArchivalSignature=function(a){if(this.bActiveX)this.activeXObj.EnablePDFArchivalSignature(a),this._elk_activeX_returnResult("EnablePDFArchivalSignature","");else{var b='{"enable":'+a+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"EnablePDFArchivalSignature","params":'+b+" }")}},

this.DoValidationBeforeSigningEx=function(a,b,c,d){if(this.bActiveX)this.activeXObj.DoValidationBeforeSigningEx(a,b,c,d),this._elk_activeX_returnResult("DoValidationBeforeSigningEx","");else{var e;null==a?e="[]":dns=JSON.stringify(a);var f='{"trusted-root-dns":'+e+',"do-rev-checking":'+b+',"ignore-validation-res":'+c+',"reseller-code":'+d+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"DoValidationBeforeSigningEx","params":'+f+" }")}},

this.SetSigningParametersEx=function(a,b,c,d){if(void 0===b&&(b=""),void 0===c&&(c=""),void 0===d&&(d=""),this.bActiveX)this.activeXObj.SetSigningParametersEx(a,b,c,d),this._elk_activeX_returnResult("SetSigningParametersEx","");else{var e='{"reason":"'+a+'","location":"'+b+'","signing-provider":"'+c+'","hash-algorithm":"'+d+'"}';this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SetSigningParametersEx","params":'+e+" }")}},

this.SetSigAppearanceParam=function(a,b,c){if(this.bActiveX)this.activeXObj.SetSigAppearanceParam(a,b,c),this._elk_activeX_returnResult("SetSigAppearanceParam","");else{c=c.replace(/\\/g,"\\\\");var d='{"visibility-position":'+a+',"page-position":'+b+',"image-path":"'+c+'"}';this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SetSigAppearanceParam","params":'+d+" }")}},

this.EnableExpiredCertEnumeration=function(a){if(this.bActiveX)this.activeXObj.EnableExpiredCertEnumeration(a),this._elk_activeX_returnResult("EnableExpiredCertEnumeration","");else{var b='{"enable":'+a+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"EnableExpiredCertEnumeration","params":'+b+" }")}},

this.GetSubjectDN=function(){var a="";if(this.bActiveX)a=this.activeXObj.GetSubjectDN(),this._elk_activeX_returnResult("GetSubjectDN",a);else{this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"GetSubjectDN","params":'+"{}"+" }")}},

this.GetIssuerDN=function(){var a="";if(this.bActiveX)a=this.activeXObj.GetIssuerDN(),this._elk_activeX_returnResult("GetIssuerDN",a);else{this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"GetIssuerDN","params":'+"{}"+" }")}},

this.SignFile=function(a,b,c,d,e,f,g,h,i){if(void 0!==i&&0==i||(i=!0),this.bActiveX)alert("SignFile functionality is not supported in this mode of signing"),this._elk_activeX_returnResult("SignFile","");else{a=a.replace(/\\/g,"\\\\"),f=f.replace(/\\/g,"\\\\");var j='{"file-to-sign":"'+a+'","signing-time":'+b+',"store-type":'+c+',"issued-to":"'+d+'","issued-by":"'+e+'","pfx-file":"'+f+'","pwd":"'+g+'","reseller-code":'+h+',"get-sig":'+i+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SignFile","params":'+j+" }")}},

this.SignPDFInMemory=function(a,b,c,d,e,f,g,h,i,j){if(void 0===i?(j=h,i=1,h=!1):void 0===j&&(j=i,i=1),this.bActiveX)alert("SignPDFInMemory functionality is not supported in this mode of signing"),this._elk_activeX_returnResult("SignPDFInMemory","");else{f=f.replace(/\\/g,"\\\\");var k='{"pdf-data":"'+a+'","signing-time":'+b+',"store-type":'+c+',"issued-to":"'+d+'","issued-by":"'+e+'","pfx-file":"'+f+'","pwd":"'+g+'","silent-mode":'+h+',"reseller-code":'+j+',"signature-format":'+i+"}";this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SignPDFInMemory","params":'+k+" }")}},

this.GetCurrentVersion=function(){if(this.bActiveX)alert("GetCurrentVersion functionality is not supported in this mode of signing"),this._elk_activeX_returnResult("GetCurrentVersion","");else{this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"GetCurrentVersion","params":'+"{}"+" }")}},

this.SetUpdateInfoURL=function(a){if(this.bActiveX)alert("SetUpdateInfoURL functionality is not supported in this mode of signing"),this._elk_activeX_returnResult("SetUpdateInfoURL","");else{var b='{"update-info-url":"'+a+'"}';this._elk_send_request('{ "msg":"sign","ver":0,"cmd":"SetUpdateInfoURL","params":'+b+" }")}}

}

function _elk_switchToActiveX(){var b="Your browser neither supports Websockets nor ActiveX plugins. Please upgrade your browser version or use a different browser";if(!_elk_desksignObj.bActiveX&&0==_elk_desksignObj._elk_connectionOpen)if(bIE||bFirefox){var c=document.createElement("div");if(c.innerHTML='<object id="pluginSign" type="application/x-ELOCK-Plugin-For-Desk-Sign" height="0" width="0"></object>',document.body.appendChild(c),_elk_desksignObj.activeXObj=document.getElementById("pluginSign"),void 0===_elk_desksignObj.activeXObj||null==_elk_desksignObj.activeXObj)return void _elk_savealert("Error: "+b);try{_elk_desksignObj.activeXObj.GetOperationStatusString()}catch(a){return void _elk_savealert("Error: "+b)}_elk_desksignObj.bActiveX=!0}else _elk_savealert("Error in loading E-Lock Signing component for this browser. Please use Internet Explorer or Firefox")}

function _elk_savealert(a){_elk_lastmsg=a}

function _elk_onopenfn(){_elk_enablePage(),_elk_desksignObj._elk_connectionOpen=!0}

function _elk_onclosefn(){_elk_desksignObj._elk_connectionOpen=!1,_elk_desksignObj._elk_socket=null,_elk_new_proto&&_elk_err_try?(_elk_new_proto=!1,_elk_err_try=!1,_elk_desksignObj._elk_socket=null,_elk_desksignObj._elk_open(),_elk_desksignObj._elk_setCallback(_elk_desksignObj._elk_userCallback)):(_elk_enablePage(),_elk_switchToActiveX())}

function _elk_onerrorfn(){_elk_new_proto?_elk_err_try=!0:_elk_enablePage()}

function _elk_round8(a){var b="";for(i=0;i<8;i++)b=a%10+b,a=Math.floor(a/10);return b}

function _elk_createDiv(){var a=document.createElement("div");a.id="_elk_disablingDiv",document.body.insertBefore(a,document.body.childNodes[0]);var b=document.createElement("STYLE"),c=document.createTextNode("#_elk_disablingDiv {display: none; z-index:10001; position: absolute; top: 0%; left: 0%; width: 100%; height: 100%; background-color: white; opacity:.00; filter: alpha(opacity=00);}");try{b.appendChild(c),document.head.insertBefore(b,document.head.childNodes[0])}catch(a){}}

function _elk_disablePage(){document.getElementById("_elk_disablingDiv").style.display="block"}

function _elk_enablePage(){document.getElementById("_elk_disablingDiv").style.display="none"}

function _elk_detectBrowser(){-1!=navigator.userAgent.indexOf("Edge")?bEdge=!0:-1!=navigator.userAgent.indexOf("Firefox")?bFirefox=!0:-1!=navigator.userAgent.indexOf("Chrome")?bChrome=!0:bIE=!0}

function _elk_byteLength(a){for(var b=a.length,c=a.length-1;c>=0;c--){var d=a.charCodeAt(c);d>127&&d<=2047?b++:d>2047&&d<=65535&&(b+=2),d>=56320&&d<=57343&&c--}return b}

var _elk_DSObjCount=0,
_elk_desksignObj=new _elk_DS,_elk_new_proto=!0,
_elk_err_try=!1,
_elk_send_queue=[],
_elk_lastmsg="",
bChrome=!1,
bFirefox=!1,
bEdge=!1,
bIE=!1;
